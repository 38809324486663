import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainRoutes from "./main-routes";

const RootRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<MainRoutes />} />
            </Routes>
        </BrowserRouter>
    );
};

export default RootRoutes;
