const ChannelIcon = () => (
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_56)">
            <path d="M26.72 18.6528C26.72 18.1224 26.9307 17.6137 27.3058 17.2386C27.6809 16.8635 28.1896 16.6528 28.72 16.6528H40.72C41.2505 16.6528 41.7592 16.8635 42.1342 17.2386C42.5093 17.6137 42.72 18.1224 42.72 18.6528V38.6528C42.72 39.1833 42.5093 39.692 42.1342 40.067C41.7592 40.4421 41.2505 40.6528 40.72 40.6528H28.72C28.1896 40.6528 27.6809 40.4421 27.3058 40.067C26.9307 39.692 26.72 39.1833 26.72 38.6528V18.6528Z" stroke="url(#paint0_linear_1_56)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M36.72 16.6528V10.6528C36.72 10.1224 36.5093 9.61369 36.1342 9.23862C35.7592 8.86355 35.2505 8.65283 34.72 8.65283H8.72003C8.1896 8.65283 7.68089 8.86355 7.30582 9.23862C6.93075 9.61369 6.72003 10.1224 6.72003 10.6528V34.6528C6.72003 35.1833 6.93075 35.692 7.30582 36.067C7.68089 36.4421 8.1896 36.6528 8.72003 36.6528H26.72" stroke="url(#paint1_linear_1_56)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M32.72 18.6528H36.72" stroke="url(#paint2_linear_1_56)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_1_56" x1="34.72" y1="16.6528" x2="34.72" y2="40.6528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint1_linear_1_56" x1="21.72" y1="8.65283" x2="21.72" y2="36.6528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint2_linear_1_56" x1="34.72" y1="18.6528" x2="34.72" y2="19.6528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <clipPath id="clip0_1_56">
                <rect width="48" height="48" fill="white" transform="translate(0.720032 0.652832)" />
            </clipPath>
        </defs>
    </svg>
)

export default ChannelIcon;