import React from 'react';
import { Grid, Box, Typography, Paper, useMediaQuery } from '@mui/material';
import ClockIcon from '../../assets/icons/ClockIcon';
import ChannelIcon from '../../assets/icons/ChannelIcon';
import SolutionsIcon from '../../assets/icons/SolutionsIcon';
import TechIcon from '../../assets/icons/TechIcon';
import ProfileIcon from '../../assets/icons/ProfileIcon';
import EmailIcon from '../../assets/icons/EmailIcon';
import PhoneIcon from '../../assets/icons/PhoneIcon';

const Home = () => {
    const isDesktop = useMediaQuery('(min-width:600px)');

    return (
        <Box
            sx={{
                width: isDesktop ? '100%' : 'calc(100% - 40px)',
                minHeight: '100vh',
                backgroundColor: '#181818',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: { xs: '20px', md: '0' }, // Add margin around the body for mobile
                '::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    filter: 'blur(5px)',
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${require('../../assets/images/bg.jpg')})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center', // Center the background image
                    backgroundRepeat: 'no-repeat',
                    opacity: 0.5,
                    zIndex: 0,
                },
            }}
        >
            <img
                src={require('../../assets/images/logo.png')}
                alt="Voxis Group Logo"
                style={{
                    width: '161px',
                    height: '136px',
                    zIndex: 100,
                    marginTop: '20px', // Move the logo down 20px
                }}
            />

            <Typography
                variant="h3"
                sx={{
                    color: 'white',
                    pt: 2,
                    pb: { xs: 5, md: 10 },
                    lineHeight: '150%',
                    fontWeight: 'bold',
                    fontSize: { xs: 28, md: 38 },
                    textAlign: 'center',
                    zIndex: 100,
                    marginTop: { xs: '10px', md: '20px' }
                }}
            >
                Exciting New Call Center Launching Soon!
            </Typography>

            <Grid container spacing={2} maxWidth="lg" sx={{ zIndex: 100 }}>
                {/* Left Side - What we offer */}
                <Grid item xs={12} md={7.5}>
                    <Paper
                        elevation={3}
                        sx={{
                            padding: { xs: '20px', md: '40px' },
                            backgroundColor: 'rgba(0, 21, 43, 0.8)',
                            borderRadius: '10px',
                            height: 'auto',
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                color: 'white',
                                fontWeight: 'bold',
                                marginBottom: '20px',
                                fontSize: { xs: '22px', md: '28px' },
                            }}
                        >
                            What we offer
                        </Typography>

                        {[
                            { icon: <ClockIcon />, title: '24/7 Customer Support', desc: 'Round-the-clock assistance to address your needs anytime, anywhere.' },
                            { icon: <ChannelIcon />, title: 'Multichannel Communication', desc: 'Support through phone, email, and live chat for a seamless experience.' },
                            { icon: <SolutionsIcon />, title: 'Tailored Solutions', desc: 'Customizable service packages to fit the unique needs of your business.' },
                            { icon: <TechIcon />, title: 'Experienced Agents', desc: 'Skilled professionals trained to provide top-quality service and problem resolution.' },
                            { icon: <ProfileIcon />, title: 'Advanced Technology', desc: 'Skilled professionals trained to provide top-quality service and problem resolution.' },
                        ].map((offer, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '20px',
                                    flexDirection: { xs: 'row', md: 'row' }, // Align icons to the left on all screen sizes
                                }}
                            >
                                <Box sx={{ marginRight: { md: '20px', xs: '5px' }, marginBottom: { xs: '10px', md: '0' }, }}>
                                    {offer.icon}
                                </Box>
                                <Box>
                                    <Typography variant="h6" color="white" sx={{ fontWeight: '500', fontSize: { xs: 16, md: 18 } }}>
                                        {offer.title}
                                    </Typography>
                                    <Typography variant="body2" color="white" sx={{ color: '#A5A5A5', fontSize: { xs: 14, md: 16 }, fontWeight: '500' }}>
                                        {offer.desc}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Paper>
                </Grid>

                {/* Right Side - Contact Information */}
                <Grid item xs={12} md={4.5}>
                    <Paper
                        elevation={3}
                        sx={{
                            padding: { xs: '20px', md: '40px' },
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            borderRadius: '10px',
                            height: 'auto',
                            marginBottom: { xs: '50px', md: '0px' }, // Add margin on mobile
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#000',
                                fontWeight: '600',
                                fontSize: { xs: '25px', md: '35px' },
                                lineHeight: '44.5px',
                                marginBottom: '20px',
                            }}
                        >
                            Stay tuned for more updates and get ready to experience superior customer support!
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '10px',
                            }}
                        >
                            <EmailIcon />
                            <Typography variant="body1" color="black" sx={{ marginLeft: '8px', fontSize: '16px', fontWeight: '500' }}>
                                Email
                            </Typography>
                        </Box>
                        <Typography variant="body1" color="black" sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '26px' }}>
                            <a target="_blank" href="mailto:info@voxisgroup.com" style={{ textDecoration: 'none', color: 'black' }} rel="noreferrer">
                                info@voxisgroup.com
                            </a>
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '10px',
                            }}
                        >
                            <PhoneIcon />
                            <Typography variant="body1" color="black" sx={{ marginLeft: '8px', fontSize: '16px', fontWeight: '500' }}>
                                Phone Number
                            </Typography>
                        </Box>
                        <Typography variant="body1" color="black" sx={{ fontSize: '16px', paddingBottom: 9, fontWeight: 'bold' }}>
                            <a href="tel:+13475052653" style={{ textDecoration: 'none', color: 'black' }} rel="noreferrer">
                                +1 (347) 505-2653
                            </a>
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Home;
