
const ClockIcon = () => (
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_37)">
            <path d="M18.716 41.6228C15.2075 40.3814 12.1699 38.083 10.0216 35.0439C7.87332 32.0048 6.71986 28.3746 6.72003 24.6528C6.72003 22.289 7.18562 19.9484 8.0902 17.7645C8.99478 15.5807 10.3207 13.5964 11.9921 11.9249C13.6636 10.2535 15.6479 8.92759 17.8317 8.023C20.0156 7.11842 22.3562 6.65283 24.72 6.65283C27.0838 6.65283 29.4245 7.11842 31.6083 8.023C33.7922 8.92759 35.7765 10.2535 37.448 11.9249C39.1194 13.5964 40.4453 15.5807 41.3499 17.7645C42.2544 19.9484 42.72 22.289 42.72 24.6528" stroke="url(#paint0_linear_1_37)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M24.72 14.6528V24.6528" stroke="url(#paint1_linear_1_37)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M24.72 30.6528H28.72C29.2505 30.6528 29.7592 30.8635 30.1342 31.2386C30.5093 31.6137 30.72 32.1224 30.72 32.6528V34.6528C30.72 35.1833 30.5093 35.692 30.1342 36.067C29.7592 36.4421 29.2505 36.6528 28.72 36.6528H26.72C26.1896 36.6528 25.6809 36.8635 25.3058 37.2386C24.9307 37.6137 24.72 38.1224 24.72 38.6528V40.6528C24.72 41.1833 24.9307 41.692 25.3058 42.067C25.6809 42.4421 26.1896 42.6528 26.72 42.6528H30.72" stroke="url(#paint2_linear_1_37)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M37.72 30.6528V34.6528C37.72 35.1833 37.9307 35.692 38.3058 36.067C38.6809 36.4421 39.1896 36.6528 39.72 36.6528H41.72" stroke="url(#paint3_linear_1_37)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M42.72 30.6528V42.6528" stroke="url(#paint4_linear_1_37)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_1_37" x1="24.72" y1="6.65283" x2="24.72" y2="41.6228" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint1_linear_1_37" x1="25.22" y1="14.6528" x2="25.22" y2="24.6528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint2_linear_1_37" x1="27.72" y1="30.6528" x2="27.72" y2="42.6528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint3_linear_1_37" x1="39.72" y1="30.6528" x2="39.72" y2="36.6528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint4_linear_1_37" x1="43.22" y1="30.6528" x2="43.22" y2="42.6528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <clipPath id="clip0_1_37">
                <rect width="48" height="48" fill="white" transform="translate(0.720032 0.652832)" />
            </clipPath>
        </defs>
    </svg>
)

export default ClockIcon;