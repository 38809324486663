const SolutionsIcon = () => (
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_70)">
            <path d="M24.72 6.65283L40.72 15.6528V33.6528L24.72 42.6528L8.72003 33.6528V15.6528L24.72 6.65283Z" stroke="url(#paint0_linear_1_70)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M24.72 24.6528L40.72 15.6528" stroke="url(#paint1_linear_1_70)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M24.72 24.6528V42.6528" stroke="url(#paint2_linear_1_70)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M24.72 24.6528L8.72003 15.6528" stroke="url(#paint3_linear_1_70)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M32.72 11.1528L16.72 20.1528" stroke="url(#paint4_linear_1_70)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_1_70" x1="24.72" y1="6.65283" x2="24.72" y2="42.6528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint1_linear_1_70" x1="32.72" y1="15.6528" x2="32.72" y2="24.6528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint2_linear_1_70" x1="25.22" y1="24.6528" x2="25.22" y2="42.6528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint3_linear_1_70" x1="16.72" y1="15.6528" x2="16.72" y2="24.6528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint4_linear_1_70" x1="24.72" y1="11.1528" x2="24.72" y2="20.1528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <clipPath id="clip0_1_70">
                <rect width="48" height="48" fill="white" transform="translate(0.720032 0.652832)" />
            </clipPath>
        </defs>
    </svg>)

export default SolutionsIcon;