import type { ThemeOptions } from '@mui/material/styles';

export const componentsOverrides: ThemeOptions['components'] = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    variants: [
      {
        props: { variant: 'subtle' },
        style: ({ theme }) => ({
          color: 'white',
          backgroundColor: theme.palette.transparent[300],
          '&:hover': {
            backgroundColor: theme.palette.transparent[400],
          },
          '& svg': {
            fill: 'white',
          },
        }),
      },
      {
        props: { variant: 'subtleBordered' },
        style: ({ theme }) => ({
          color: 'white',
          backgroundColor: theme.palette.transparent[300],
          border: '1px solid',
          borderColor: theme.palette.primary.main,

          '&:hover': {
            backgroundColor: theme.palette.transparent[400],
          },
          '& .MuiLoadingButton-loadingIndicator': {
            color: theme.palette.neutral[600],
          },
          '&.Mui-disabled:not(.MuiLoadingButton-loading)': {
            color: theme.palette.neutral[600],
          },
        }),
      },
      {
        props: { variant: 'subtleBordered', disabled: true },
        style: ({ theme }) => ({
          borderColor: theme.palette.neutral[600],
          color: theme.palette.neutral[100],
        }),
      },
    ],
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        ...(ownerState.variant === 'contained' && {
          boxShadow: 'none',
          ':hover': {
            boxShadow: theme.customShadows.low,
          },
        }),
        ...(ownerState.variant === 'outlined' && {
          borderColor: theme.palette.neutral[500],
        }),
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        height: 48,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        top: -4,
      },
      shrink: {
        top: 0,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => ({
        margin: '2px 0 0',
        ...theme.typography.small,
      }),
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      fontSizeSmall: {
        fontSize: '16px',
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      rounded: {
        borderRadius: '8px',
      },
    },
  },
};
