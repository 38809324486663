const TechIcon = () => (
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_100)">
            <path d="M10.72 12.1528C10.72 11.6224 10.9307 11.1137 11.3058 10.7386C11.6809 10.3635 12.1896 10.1528 12.72 10.1528H36.72C37.2505 10.1528 37.7592 10.3635 38.1342 10.7386C38.5093 11.1137 38.72 11.6224 38.72 12.1528V36.1528C38.72 36.6833 38.5093 37.192 38.1342 37.567C37.7592 37.9421 37.2505 38.1528 36.72 38.1528H12.72C12.1896 38.1528 11.6809 37.9421 11.3058 37.567C10.9307 37.192 10.72 36.6833 10.72 36.1528V12.1528Z" stroke="url(#paint0_linear_1_100)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.72 20.1528V16.1528H20.72M32.72 28.1528V32.1528H28.72M20.72 32.1528H16.72V28.1528M32.72 20.1528V16.1528H28.72" stroke="url(#paint1_linear_1_100)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.72003 20.1528H10.72" stroke="url(#paint2_linear_1_100)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.72003 28.1528H10.72" stroke="url(#paint3_linear_1_100)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.72 6.15283V10.1528" stroke="url(#paint4_linear_1_100)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M28.72 6.15283V10.1528" stroke="url(#paint5_linear_1_100)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M42.72 20.1528H38.72" stroke="url(#paint6_linear_1_100)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M42.72 28.1528H38.72" stroke="url(#paint7_linear_1_100)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M28.72 42.1528V38.1528" stroke="url(#paint8_linear_1_100)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.72 42.1528V38.1528" stroke="url(#paint9_linear_1_100)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_1_100" x1="24.72" y1="10.1528" x2="24.72" y2="38.1528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint1_linear_1_100" x1="24.72" y1="16.1528" x2="24.72" y2="32.1528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint2_linear_1_100" x1="8.72003" y1="20.1528" x2="8.72003" y2="21.1528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint3_linear_1_100" x1="8.72003" y1="28.1528" x2="8.72003" y2="29.1528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint4_linear_1_100" x1="21.22" y1="6.15283" x2="21.22" y2="10.1528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint5_linear_1_100" x1="29.22" y1="6.15283" x2="29.22" y2="10.1528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint6_linear_1_100" x1="40.72" y1="20.1528" x2="40.72" y2="21.1528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint7_linear_1_100" x1="40.72" y1="28.1528" x2="40.72" y2="29.1528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint8_linear_1_100" x1="29.22" y1="38.1528" x2="29.22" y2="42.1528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint9_linear_1_100" x1="21.22" y1="38.1528" x2="21.22" y2="42.1528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <clipPath id="clip0_1_100">
                <rect width="48" height="48" fill="white" transform="translate(0.720032 0.152832)" />
            </clipPath>
        </defs>
    </svg>
)

export default TechIcon;