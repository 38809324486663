export const backgrounds = {
  fourCorners:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(/artworks/corners.jpg) center/cover no-repeat',
  orangePills:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(/artworks/orange-pills.jpg) center/cover no-repeat',
  sunrise:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(/artworks/sunrise.jpg) center/cover no-repeat',
  bluePurple: 'linear-gradient(208.56deg, #2D64EF 0.63%, #8E2DEF 100%)',
  purple:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), #2F336B',

  content:
    'linear-gradient(180deg, rgba(0, 0, 0, 0.245) 0%, rgba(0, 0, 0, 0.5) 60%), linear-gradient(180deg, rgba(0, 119, 212, 0.75) 0%, rgba(252, 0, 255, 0.75) 100%);',
  class:
    'linear-gradient(180deg, rgba(0, 0, 0, 0.245) 0%, rgba(0, 0, 0, 0.5) 60%), linear-gradient(180deg, rgba(0, 212, 72, 0.75) 0%, rgba(0, 26, 255, 0.75) 100%);',
  playlist:
    'linear-gradient(180deg, rgba(0, 0, 0, 0.245) 0%, rgba(0, 0, 0, 0.5) 60%), linear-gradient(180deg, rgba(252, 255, 128, 0.75) 0%, rgba(233, 9, 92, 0.75) 100%);',
  profile:
    'linear-gradient(180deg, rgba(0, 0, 0, 0.245) 0%, rgba(0, 0, 0, 0.5) 60%), linear-gradient(180deg, rgba(126, 87, 240, 0.75) 0%, rgba(0, 176, 255, 0.75) 100%);',
};
