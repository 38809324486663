import React from 'react';
import { createTheme } from '@mui/material/styles';
import { shadows } from './shadows';
import { createTypography } from './typography';
import { componentsOverrides } from './overrides';
import { colors } from './colors';
import { gradients } from './gradients';
import { backgrounds } from './backgrounds';

const theme = createTheme({
  components: componentsOverrides,
  typography: createTypography,
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
  palette: {
    common: {
      black: colors.common.black,
      white: colors.common.white,
    },
    primary: {
      light: colors.primary['200'],
      main: colors.primary['300'],
      dark: colors.primary['400'],
      contrastText: colors.common.white,
      ...colors.primary,
    },
    secondary: {
      light: colors.secondary['100'],
      main: colors.secondary['200'],
    },
    error: {
      light: colors.error['200'],
      main: colors.error['300'],
      dark: colors.error['400'],
      contrastText: colors.common.white,
    },
    warning: {
      light: colors.warning['200'],
      main: colors.warning['300'],
      dark: colors.warning['400'],
      contrastText: colors.common.white,
    },
    success: {
      light: colors.success['200'],
      main: colors.success['300'],
      dark: colors.success['400'],
      contrastText: colors.common.white,
    },
    neutral: {
      ...colors.neutral,
    },
    transparent: {
      ...colors.transparent,
    },
    text: {
      primary: colors.neutral['1000'],
      secondary: colors.neutral['700'],
      disabled: colors.neutral['600'],
    },
    divider: colors.neutral['400'],
  },
  customShadows: shadows,
  gradients,
  backgrounds,
});

/* eslint-disable no-unused-vars */
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    subtle: true;
    subtleBordered: true;
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    customShadows: typeof shadows;
    gradients?: typeof gradients;
    backgrounds: typeof backgrounds;
  }

  interface Palette {
    neutral: typeof colors.neutral;
    transparent: typeof colors.transparent;
  }

  interface ThemeOptions {
    customShadows?: typeof shadows;
    gradients?: typeof gradients;
    backgrounds?: typeof backgrounds;
  }

  interface PaletteOptions {
    neutral: typeof colors.neutral;
    transparent: typeof colors.transparent;
  }

  interface TypographyVariants {
    body: React.CSSProperties;
    body_bold: React.CSSProperties;
    body_paragraph: React.CSSProperties;
    small: React.CSSProperties;
    small_bold: React.CSSProperties;
    small_paragraph: React.CSSProperties;
    xsmall: React.CSSProperties;
    xsmall_bold: React.CSSProperties;
    xsmall_paragraph: React.CSSProperties;
    tiny: React.CSSProperties;
    tiny_bold: React.CSSProperties;
    tiny_paragraph: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body?: React.CSSProperties;
    body_bold?: React.CSSProperties;
    body_paragraph?: React.CSSProperties;
    small?: React.CSSProperties;
    small_bold?: React.CSSProperties;
    small_paragraph?: React.CSSProperties;
    xsmall?: React.CSSProperties;
    xsmall_bold?: React.CSSProperties;
    xsmall_paragraph?: React.CSSProperties;
    tiny?: React.CSSProperties;
    tiny_bold?: React.CSSProperties;
    tiny_paragraph?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body: true;
    body_bold: true;
    body_paragraph: true;
    small: true;
    small_bold: true;
    small_paragraph: true;
    xsmall: true;
    xsmall_bold: true;
    xsmall_paragraph: true;
    tiny: true;
    tiny_bold: true;
    tiny_paragraph: true;
  }
}

export default theme;
