export const colors = {
  common: {
    white: '#fff',
    black: '#000',
  },
  primary: {
    100: '#F8F8F8',
    200: '#EEF2F6',
    300: '#000',
    400: '#F1F1F1',
    500: '#1F2176',
    600: 'black', //main,
    700: '#E8E8E8',
  },
  secondary: {
    100: '#EDE7F6',
    200: '#5E35B1'
  },
  blue: {
    100: '#E3F2FD',
    200: '#2196F3',
  },
  neutral: {
    100: '#515151',
    200: '#AAAAAA',
    300: '#D9D9D9',
    400: '#919191',
    500: '#CDD3DD',
    600: '#A8B1BD',
    700: '#6A7381',
    800: '#49505A',
    900: '#1F2329',
    1000: '#121417',
    1100: '#585c64',
  },
  success: {
    100: '#DDFDED',
    200: '#0AC295',
    300: '#09A57F',
    400: '#078364',
    500: '#027357',
  },
  warning: {
    100: '#FEF7B9',
    200: '#FFDA6C',
    300: '#FFB400',
    400: '#E07C02',
    500: '#C33E01',
  },
  error: {
    100: '#FCD2CF',
    200: '#F45532',
    300: '#DF320C',
    400: '#C61A0B',
    500: '#AE0A0A',
  },
  transparent: {
    0: 'transparent',
    100: 'rgba(247, 249, 252, 0.55)',
    200: 'rgba(203, 211, 223, 0.25)',
    300: 'rgba(0, 0, 0, 0.25)',
    400: 'rgba(0, 0, 0, 0.5)',
    500: 'rgba(0, 0, 0, 0.75)',
  },
};
