import { TypographyOptions } from '@mui/material/styles/createTypography';
// import "@fontsource/inter";

export const createTypography = (): TypographyOptions => ({
  htmlFontSize: 16,
  // fontFamily: 'Inter',
  h1: {
    fontWeight: 700,
    fontSize: '34px',
    lineHeight: '40px',
  },
  h2: {
    fontWeight: 700,
    fontSize: '26px',
    lineHeight: '34px',
  },
  h3: {
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: '24px',
  },
  h4: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '22px',
  },
  body: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
  },
  body_bold: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
  },
  body_paragraph: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  small: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
  },
  small_bold: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
  },
  small_paragraph: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
  },
  xsmall: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
  xsmall_bold: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
  },
  xsmall_paragraph: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
  },
  tiny: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '12px',
  },
  tiny_bold: {
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '12px',
  },
  tiny_paragraph: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '18px',
  },
  button: {
    textTransform: 'none',
    fontWeight: 700,
  },
});
