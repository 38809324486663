const ProfileIcon = () => (
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2_134)">
            <path d="M16.72 14.1528C16.72 16.2746 17.5629 18.3094 19.0632 19.8097C20.5635 21.31 22.5983 22.1528 24.72 22.1528C26.8418 22.1528 28.8766 21.31 30.3769 19.8097C31.8772 18.3094 32.72 16.2746 32.72 14.1528C32.72 12.0311 31.8772 9.99627 30.3769 8.49598C28.8766 6.99569 26.8418 6.15283 24.72 6.15283C22.5983 6.15283 20.5635 6.99569 19.0632 8.49598C17.5629 9.99627 16.72 12.0311 16.72 14.1528Z" stroke="url(#paint0_linear_2_134)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.72 42.1528V38.1528C12.72 36.0311 13.5629 33.9963 15.0632 32.496C16.5635 30.9957 18.5983 30.1528 20.72 30.1528H28.72C30.8418 30.1528 32.8766 30.9957 34.3769 32.496C35.8772 33.9963 36.72 36.0311 36.72 38.1528V42.1528" stroke="url(#paint1_linear_2_134)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_2_134" x1="24.72" y1="6.15283" x2="24.72" y2="22.1528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <linearGradient id="paint1_linear_2_134" x1="24.72" y1="30.1528" x2="24.72" y2="42.1528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2078D0" />
                <stop offset="1" stop-color="#409CD2" />
            </linearGradient>
            <clipPath id="clip0_2_134">
                <rect width="48" height="48" fill="white" transform="translate(0.720032 0.152832)" />
            </clipPath>
        </defs>
    </svg>
)

export default ProfileIcon;