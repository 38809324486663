import React from "react";
import "./App.css";
import "react-quill/dist/quill.snow.css";
import theme from "./theme";
import { ThemeProvider } from "@emotion/react";
import RootRoutes from "./routes";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RootRoutes />
    </ThemeProvider>
  )
}

export default App;
